<template>
  <div class="newSeeds">
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column prop="time" label="登录时间" show-overflow-tooltip align="center"> </el-table-column>
      <el-table-column prop="userName" label="用户名" show-overflow-tooltip align="center"> </el-table-column>
      <!-- <el-table-column prop="waringType" label="预警类型" show-overflow-tooltip align="center"></el-table-column> -->
      <!-- <el-table-column prop="loginNumber" label="登录失败次数" show-overflow-tooltip align="center"></el-table-column> -->
      <!-- <el-table-column prop="passwordError" label="密码错误次数" show-overflow-tooltip align="center"></el-table-column> -->
      <el-table-column prop="loginSuccess" label="登录结果" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="loginType" label="登录类型" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="ip" label="ip" show-overflow-tooltip align="center"></el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[8, 10, 50, 100, 150]"
        :page-size="8"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      boxPrompt: '',
      nowIndex: 0,
      updateForm: false,
      currentPage4: 1,
      multipleSelection: [],
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      logisticsForm: {
        name: '',
      },
      value2: '',
      page: 1,
      size: 8,
      listTotal: 0,
      loading: true,
      type: 18,
      searchStatus: false,
      timeValue: '',
    };
  },
  mounted() {
    this.requrstList();
  },
  methods: {
    //请求列表数据
    requrstList() {
      this.$get('/system/getInfo', { page: this.page, size: this.size }).then((res) => {
        if (res.data.state == 'success') {
          //console.log(res);
          this.listTotal = res.data.count;
          this.tableData = res.data.datas;
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleEdit() {},
    handleDelete() {},
    handleSizeChange(val) {
      this.size = val;
      this.requrstList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
    handleSelectionChange() {},
    timestampToTime(before) {
      var date = new Date();
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + before) : date.getMonth() + before) + '-';
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + ' ';
      var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() + ':';
      var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() + ':';
      var ss = date.getSeconds() < 10 ? '0' + date.getDate() : date.getSeconds();
      return Y + M + D + ' ' + hh + ':' + mm + ss;
    },
  },
};
</script>

<style scoped lang="less">
@import '@/style/backTable.less';
.operation {
  display: flex;
}
.newSeeds /deep/ .el-table td,
.el-table th {
  padding: 1.11vh 0;
}
.newSeeds {
  max-height: 90vh;
}
// .newSeeds /deep/ .el-table__body-wrapper {
//   max-height: 77vh;
//   overflow-y: auto;
// }
</style>
